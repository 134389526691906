import { FooterDataController } from '@adc/utilities/footer-data-controller';
import { GlobalTranslations } from '@adc/utilities/global-translations';
import { Log } from '@adc/utilities/logger-controller';
import { TranslationDataController } from '@adc/utilities/translation-data-controller';
import '@aileron/divider';
import { registerComponent as registerGrid } from '@aileron/grid';
import { registerComponent as registerIcon } from '@aileron/icon';
import { registerComponent as registerLink } from '@aileron/link';
import { LitElement, html } from 'lit';
import { customElement, property} from 'lit/decorators.js';
import { version } from '../package.json';
import styles from './styles.scss';

registerGrid('hp-footer');
registerIcon('hp-footer');
registerLink('hp-footer');

/*
* A data-driven content notification element based on @aileron/notification.
*
* Does a fetch for a json file from pubcontent and wires up the
* parameters for the ability to update live from CMS
*/

@customElement('adc-footer')

export class Footer extends LitElement {

  @property({ type: String, reflect: true }) localeCode = "en_US";

  /**
   * @private
   */
  static styles = styles;
  private startTime: number;

  /**
   * Sets the file containing the JSON to fetch for display
   * @type {string}
   */
  private readonly data = new FooterDataController(this);
  @property({type: Number}) loadTime: number;

  @Log
  render() {
    this.startTime = new Date().getTime();
    this.localeCode = TranslationDataController.retrieveLocale();
    TranslationDataController.getLocaleTranslations(this.localeCode, GlobalTranslations);

    return html`
    <footer id="aa-footer">
      ${this.displayDesktop(this.data)}
      ${this.displayMobile(this.data.linkData)}
    </footer>
    `
  }

  protected async getUpdateComplete(): Promise<boolean> {
    const result = await super.getUpdateComplete();
    this.loadTime = new Date().getTime() - this.startTime;
    return result;
  }

  displayDesktop(data) {  
    return html`
    <hp-footer-grid part="grid-container-desktop" class="grid-container mobile-view--hidden">
        <adc-row class="desktop-footer-row">
          ${this.desktopLinksConfig(data)}
        </adc-row>
        <adc-row>
          <adc-column col-desktop="12">
            <adc-divider class="divider"></adc-divider>
          </adc-column>
        </adc-row>
        <adc-row class="desktop-note">
          ${this.displayDesktopNote(data)}
        </adc-row>
        <adc-row class="tab-note">
          ${this.displayTabNote(data)}
        </adc-row>
    </hp-footer-grid>
  `;
  }

  displayMobile(data) {
    return html`
    <hp-footer-grid part="grid-container-mobile" id=${window.location.href.includes('/homePage.do') ? 'mobileGridContainer' : ''} class="grid-container mobile-view--visible">
        <adc-row class="mobile-footer-row">
          ${this.mobileLinksConfig(data)}
        </adc-row>
        <adc-row>
          <adc-column>
            <adc-divider class="divider"></adc-divider>
          </adc-column>
        </adc-row>
        <adc-row class="mobile-note">
          ${this.displayMobileNote(data)}
        </adc-row>
    </hp-footer-grid>
  `;
  }

  desktopLinksConfig(data) {
    return html`
      ${data.linkData?.desktopConfig?.columns.map(
        (column) => html`
          <adc-column col-desktop="3">
            <h2>${column.Heading}</h2>
            <adc-divider class="divider"></adc-divider>
            ${column.Links.map((link) => html`
              <hp-footer-link class="footer-links content-link"
                        hidden-label-text="${(link.newWin ? (link.external ? data.linkData?.desktopConfig?.newWinTextExternal : data.linkData?.desktopConfig?.newWinText) : '')}"
                        icon="${(link.newWin ? 'new-window' : '')}"
                        target="${link.newWin ? '_blank' : '_self'}"
                        href="${link.URL}">
                ${link.Link}
              </hp-footer-link>`)}
          </adc-column>`)}

      <div class="target-ads">
        ${data.targetDetails?.map(
          (imageLink) => html`
            <a class="content-ads" href="${imageLink.imageLink}" target="_blank">
              <img alt="${imageLink.altText}" src="${imageLink.image}">
            </a>
          `)}
      </div>
    `;
  }

  mobileLinksConfig(data) {
    return html`
    ${data?.mobileConfig?.columns.map(
      (column) => html`
      <adc-column>
            ${column.Links.map((link) => html`
            <hp-footer-link class="mobile-footer-links content-link" hidden-label-text="${(link.newWin ? (link.external ? data?.desktopConfig?.newWinTextExternal : data?.desktopConfig?.newWinText) : '')}" icon="${(link.newWin ? 'new-window' : '')}" target="${link.newWin ? '_blank' : '_self'}" href="${link.URL}">
            ${link.Link}
            </hp-footer-link>`)}
      </adc-column>
  `)}
  `;
  }

  displayDesktopNote(data) {
    return html`
      <adc-column col-desktop="10" col-tablet="6">
        <div class="footer-left">
          ${this.renderFootNotes(data)}
        </div>
      </adc-column>
      <adc-column col-desktop="2" col-tablet="2">
        <div class="footer-right">
        ${data?.linkData?.desktopConfig?.socialLinks.map((socialLink) => html`
          <a class="social-links" href="${socialLink.href}" target="_blank">
            <img alt="${socialLink.image.alternateText}" src="${socialLink.image.file}">
          </a>
        `)}
        </div>
      </adc-column>
    `;
  }

  displayTabNote(data) {
    return html`
      <adc-column col-desktop="12" col-tablet="12">
        <div class="footer-left">
          ${this.renderFootNotes(data)}
        </div>
        <adc-row class="tab-footer-right">
          <adc-column col-tablet="4">
            <a id="header-one-world-logo"
              href="https://www.aa.com/i18n/travel-info/partner-airlines/oneworld-airline-partners.jsp?from=comp_nav"
              target="_blank">
              <img class="one-world" alt="${TranslationDataController.translations["OneWorld"]}" src="/content/images/icons-logos/logos/logo-oneworld.svg">
            </a>
          </adc-column>
          <adc-column col-tablet="4">
            <div class="footer-right">
              ${data?.linkData?.desktopConfig?.socialLinks.map(
                (socialLink) => html`
                  <a class="social-links" href="${socialLink.href}" target="_blank">
                    <img alt="${socialLink.image.alternateText}" src="${socialLink.image.file}">
                  </a>
                `)}
            </div>
          </adc-column>
        </adc-row>
      </adc-column>
    `;
  }

  displayMobileNote(data) {
    this.setupMobileNote(data);
    return html`
    <adc-column col-phone="3">
      <div class="mobile-footer-note">
        ${data?.mobileConfig.NoteLinks.map(
      (noteLink) => html`
          <hp-footer-link class="content-link" target="_self" href="${noteLink.URL}">
          ${noteLink.Link}
          </hp-footer-link>
          ${noteLink.showDot ? html`<span aria-hidden="true">&nbsp;&#183;&nbsp;</span>` : html``}
          `)}
      </div>
    </adc-column>

      ${data?.mobileConfig.socialLinks.map(
        (socialLink) => html`
        <adc-column col-phone="1" class="one-world-logo">
          <a href="${socialLink.href}" target="_blank">
            <img class="mobile-footer-icon" alt="${socialLink.image.alternateText}" src="${socialLink.image.file}">
          </a>
        </adc-column>`)}
    `;
  }

  renderFootNotes(data)  {
    const newWinText = data?.linkData?.desktopConfig?.newWinText;
    const newWinTextExternal = data?.linkData?.desktopConfig?.newWinTextExternal;
    return html`
      ${data?.linkData?.desktopConfig?.footnote?.map((notelink) => {
        const key = Object.keys(notelink)[0];
        switch(key) {
          case 'multiline':
            return this.renderMultilineNote(notelink.multiline, newWinText, newWinTextExternal);
          case 'link':
            return this.renderLinkFootnote(notelink.link, newWinText, newWinTextExternal);
          case 'html':
            return this.createHTMLFragment(notelink.html);
          case 'text':
            return html`<div>${notelink.text}</div>`;
          case 'standard':
            return this.renderStandardNote(notelink.standard);
          default:
            return html``;
        }}
      )}`;
  }

  renderMultilineNote(multilineText, newWinText, newWinTextExternal) {
    return html `
      <div>${multilineText?.map((textContent) => {
        const key = Object.keys(textContent)[0]
        switch(key) {
          case 'text':
            return html`<div>${textContent.text}</div>`;
          case 'link':
            return this.renderLinkFootnote(textContent.link, newWinText, newWinTextExternal);
          case 'html':
            return this.createHTMLFragment(textContent.html);
          default:
            return html``;
        }
      })}</div><br>`;
   }

  renderStandardNote(standardText){
    if (standardText && standardText.icon && window.innerWidth < 800) {
      return html`
      <div class="note-link-standard-mobile">
        <div class="note-link-standard-text">
          <hp-footer-icon class="note-link--with-icon note-link" icon="navigation:new-window" size="16"></hp-footer-icon>
          ${standardText?.text}
        </div>
      </div>
      <div class="note-link-standard-copyright">
        ${standardText?.copyright}
      </div><br>
    `
    } else if (standardText && standardText.icon){
      return html`
        <div class="note-link-standard">
          <div>
            <hp-footer-icon class="note-link--with-icon note-link" icon="navigation:new-window" size="16"></hp-footer-icon>
            ${standardText?.text} ${standardText?.copyright}
          </div>
        </div>
        <br>
      `
    }
    return html`

      <p>${standardText?.text}${standardText?.copyright}</p>
    `
  }

  renderLinkFootnote(content, newWinText, newWinTextExt) {
    return html `<div class="note-link-hyperlink">
            <span class="note-link--hyperlink-description">${content.preLinkText}</span>
                <hp-footer-link class="note-link--hyperlink-text"
                          hidden-label-text="${ content.newWin ? (content.external ? newWinTextExt : newWinText) : ''}"
                          href="${content.href}"
                          target="${content.newWin ? '_blank' : '_self'}"
                          icon="${(content.chevron === false || content.newWin) ? '' : 'chevron'}">
                          ${content.linkText}
                          <hp-footer-icon style="${content.newWin ? '' : 'display:none'}" icon="navigation:new-window" size="16"></hp-footer-icon>
                </hp-footer-link>
            ${ content.postLinkText}
            </div>`;
  }

  createHTMLFragment(content) {
    return document.createRange().createContextualFragment(content);
  }

  get version(): string {
    return version
  }

  setupMobileNote(data) {
    data?.mobileConfig.NoteLinks.forEach((notelink, index) => {
      if (index === data?.mobileConfig?.NoteLinks.length-1) {
        notelink.showDot = false;
      } else {
        notelink.showDot = true;
      }
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'adc-footer': Footer;
  }
}
